import Objects from 'Helper/object/Object';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'Helper/metrics/NewRelicError';
import { NewRelicMetric } from 'Helper/metrics/NewRelicMetric';
import { Providers } from 'Domain/version/web/config/Providers';
import { Slot } from 'Types/Slot';
import { Config } from 'Types/Config';
import { GoogleAdsConfig, GoogleAdsApsConfig } from 'Types/ProviderSettings/GoogleAds';

export const id = Providers.GOOGLE_ADS;
export const webpackExclude = (config: Config): boolean => {
  const providerSettings = config.slots?.providerSettings?.[id] as GoogleAdsConfig | undefined;
  const valuesDefault = providerSettings?.aps?._ || {};
  const values = (providerSettings?.aps || {}) as { [k: string]: GoogleAdsApsConfig };
  return !(
    Object.values(valuesDefault).find((item) => Objects.isObject(item) && item?.enabled) ||
    Object.values(values).find((item) => Objects.isObject(item) && item?.enabled)
  );
};
export const init = () => {
  console.info('[SLOTS][GOOGLEADSAPS][INIT]');
  if (globalThis.apstag) return;
  const q = (c, r) => {
    // eslint-disable-next-line no-underscore-dangle
    globalThis.apstag._Q.push([c, r]);
  };
  globalThis.apstag = {
    init() {
      q('i', arguments);
    },
    fetchBids() {
      q('f', arguments);
    },
    setDisplayBids: () => {},
    targetingKeys: () => [],
    // eslint-disable-next-line no-underscore-dangle
    _Q: [],
  };
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADSAPS][DEPENDENCIES]');
  return [
    {
      id: 'aps',
      url: 'https://c.amazon-adsystem.com/aax2/apstag.js',
    },
  ];
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADSAPS][LOADED]');
  const apsSettings = globalThis.Baxter.config.providers?.[id]?.aps?.settings;
  if (!apsSettings || !apsSettings.publisherId || !apsSettings.timeout) {
    throw new Error(`[SLOTS][GOOGLEADSAPS][LOADED] missing global aps settings`);
  }
  console.debug('[SLOTS][GOOGLEADSAPS][LOADED]', apsSettings.publisherId, apsSettings.timeout);
  globalThis.apstag.init({
    pubID: apsSettings.publisherId,
    adServer: 'googletag',
    bidTimeout: apsSettings.timeout,
  });
};

export const setTargeting = (targeting) => {
  console.info('[SLOTS][GOOGLEADSAPS][SETTARGETING]', targeting);
};

export const transform = async (pageId, containerId, slotId, params): Promise<Slot> => {
  console.info('[SLOTS][GOOGLEADSAPS][TRANSFORM]', pageId, containerId, slotId, params);
  // @ts-ignore
  return {};
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const create = (slot: Slot, biddersCallback) => {
  console.info('[SLOTS][GOOGLEADSAPS][CREATE]', slot);
  biddersCallback();
};

export const load = (apsSlots: Slot[], biddersCallback) => {
  console.info('[SLOTS][GOOGLEADSAPS][LOAD]', apsSlots);
  if (!apsSlots.length) {
    biddersCallback();
  }
  const { apstag } = globalThis;
  if (apstag) {
    console.debug('[SLOTS][GOOGLEADSAPS][LOAD] requesting bids');
    newRelicMetrics.reportMetric(NewRelicMetric.APS_REQUESTED_BIDS, {
      command: '[GOOGLEADSAPSLOAD]',
    });
    apstag.fetchBids(
      {
        slots: apsSlots.map((apsSlot) => ({
          slotID: apsSlot.innerId,
          slotName: apsSlot.path,
          // @ts-ignore
          sizes: apsSlot.sizes?.[0]?.slot,
        })),
      },
      (bids, responseContext) => {
        try {
          if (responseContext?.fromTimeout) {
            console.debug('[SLOTS][GOOGLEADSAPS][LOAD] timed out', bids);
            newRelicMetrics.reportMetric(NewRelicMetric.APS_TIMED_OUT, {
              command: '[GOOGLEADSAPSLOAD]',
            });
          } else {
            const isArray = Array.isArray(bids);
            const sizes = Array.isArray(bids) ? bids.map((bid) => bid.size).join(',') : 'none';
            console.debug('[SLOTS][GOOGLEADSAPS][LOAD] successfully returned', isArray, sizes, bids);
            newRelicMetrics.reportMetric(NewRelicMetric.APS_SUCCESSFULLY_RETURNED, {
              command: '[GOOGLEADSAPSLOAD]',
              isArray,
              sizes,
            });
          }
          globalThis.googletag.cmd.push(() => {
            try {
              console.debug('[SLOTS][GOOGLEADSAPS][LOAD] apstag.setDisplayBids()', bids);
              apstag.setDisplayBids();
              biddersCallback();
            } catch (e) {
              console.error('[SLOTS][GOOGLEADSAPS][LOAD]', e);
              newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
                command: '[APSLOAD]',
                message: (e as Error).message,
              });
              throw e;
            }
          });
        } catch (e) {
          console.error('[SLOTS][GOOGLEADSAPS][LOAD]', e);
          newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
            command: '[GOOGLEADSAPSLOAD][BIDSBACKHANDLER]',
            message: (e as Error).message,
          });
          throw e;
        }
      }
    );
  } else {
    console.error(`[SLOTS][GOOGLEADSAPS][LOAD]`);
    newRelicMetrics.reportError(NewRelicError.APS_NO_APS, { command: '[GOOGLEADSAPSLOAD]' });
  }
};

export const refresh = (slot: Slot, biddersCallback) => {
  console.info('[SLOTS][GOOGLEADSAPS][REFRESH]', slot);
  const { apstag } = globalThis;
  if (apstag) {
    console.debug('[SLOTS][GOOGLEADSAPS][REFRESH] requesting bids');
    newRelicMetrics.reportMetric(NewRelicMetric.APS_REQUESTED_BIDS, {
      command: '[GOOGLEADSAPSREFRESH]',
    });
    apstag.fetchBids(
      {
        slots: [
          {
            slotID: slot.innerId,
            slotName: slot.path,
            // @ts-ignore
            sizes: slot.sizes?.[0]?.slot,
          },
        ],
      },
      (bids, responseContext) => {
        try {
          if (responseContext?.fromTimeout) {
            console.debug('[SLOTS][GOOGLEADSAPS][REFRESH] timed out', bids);
            newRelicMetrics.reportMetric(NewRelicMetric.APS_TIMED_OUT, {
              command: '[GOOGLEADSAPSREFRESH]',
            });
          } else {
            console.debug('[SLOTS][GOOGLEADSAPS][REFRESH] successfully returned', bids);
            newRelicMetrics.reportMetric(NewRelicMetric.APS_SUCCESSFULLY_RETURNED, {
              command: '[GOOGLEADSAPSREFRESH]',
            });
          }
          globalThis.googletag.cmd.push(() => {
            try {
              console.debug('[SLOTS][GOOGLEADSAPS][REFRESH] apstag.setDisplayBids()', bids);
              apstag.setDisplayBids();
              biddersCallback();
            } catch (e) {
              console.error('[SLOTS][GOOGLEADSAPS][REFRESH]', e);
              newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
                command: '[APSREFRESH]',
                message: (e as Error).message,
              });
              throw e;
            }
          });
        } catch (e) {
          console.error('[SLOTS][GOOGLEADSAPS][REFRESH]', e);
          newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
            command: '[GOOGLEADSAPSREFRESH][BIDSBACKHANDLER]',
            message: (e as Error).message,
          });
          throw e;
        }
      }
    );
  } else {
    console.error(`[SLOTS][GOOGLEADSAPS][REFRESH]`);
    newRelicMetrics.reportError(NewRelicError.APS_NO_APS, { command: '[GOOGLEADSAPSREFRESH]' });
  }
};

export const remove = (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEADSAPS][REMOVE]', slots);
};

export default {
  dependencies,
  init,
  loaded,
  transform,
  create,
  load,
  refresh,
  remove,
  setTargeting,
};
